import { Container } from "react-bootstrap";
import classes from "./Posters.module.scss";
import { useCallback, useEffect, useMemo } from "react";
import clsx from "clsx";
import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";

const Posters = () => {
  const posters = useMemo(
    () => [
      "10-2023-10-15.webp",
      "1-2023-10-15.webp",
      "2-2023-10-15.webp",
      "3-2023-10-15.webp",
      "4-2023-10-15.webp",
      "5-2023-10-15.webp",
      "6-2023-10-15.webp",
      "7-2023-10-15.webp",
      "8-2023-10-15.webp",
      "9-2023-10-15.webp",
    ],
    []
  );

  useEffect(() => {
    let isDown = false;
    let startX: number;
    let scrollLeft: number;

    const posterContainer = document.getElementById("posterContainer")!;
    posterContainer.addEventListener("mousedown", (e) => {
      isDown = true;
      startX = e.pageX - posterContainer.offsetLeft;
      scrollLeft = posterContainer.scrollLeft;
    });
    posterContainer.addEventListener("mousemove", (e) => {
      if (isDown) {
        e.preventDefault();
        const walk = (e.pageX - posterContainer.offsetLeft - startX) * 1.5;
        posterContainer.scrollLeft = scrollLeft - walk;
      }
    });
    posterContainer.addEventListener("mouseleave", () => {
      isDown = false;
    });
    posterContainer.addEventListener("mouseup", () => {
      isDown = false;
    });
  }, []);

  const scroll = useCallback((direction: "left" | "right") => {
    const posterContainer = document.getElementById("posterContainer")!;

    posterContainer.scrollTo({
      top: 0,
      left: posterContainer.scrollLeft - (direction === "left" ? 310 : -310),
      behavior: "smooth",
    });
  }, []);

  return (
    <div className={classes.container}>
      <Container className={clsx(classes.content, "rounded")}>
        <h2 className="text-align-center">As nossas edições anteriores</h2>
        <div className="col-12 d-flex justify-content-around align-items-center py-4">
          <div className={classes.indicators}>
            <ArrowLeft
              size={30}
              weight="bold"
              color="white"
              onClick={() => {
                scroll("left");
              }}
            />
          </div>
          <div id="posterContainer" className={classes.posterContainer}>
            {posters.map((image: string, index: number) => {
              return (
                <div
                  className="col-12 col-xl-4 me-3"
                  key={index}
                  style={{ maxWidth: "300px" }}
                >
                  <img
                    src={`/media/posters/${image}`}
                    className="col-12 rounded"
                    style={{ objectFit: "fill" }}
                  />
                </div>
              );
            })}
          </div>
          <div className={classes.indicators}>
            <ArrowRight
              size={30}
              weight="bold"
              color="white"
              onClick={() => {
                scroll("right");
              }}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Posters;
