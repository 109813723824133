import {
  FacebookLogo,
  InstagramLogo,
  YoutubeLogo,
} from "@phosphor-icons/react";
import classes from "./Footer.module.scss";

const Footer = () => {
  return (
    <div className={classes.container}>
      <div>
        <p className="fs-4 mb-0">Gatunos</p>
        <p className="fs-6 mb-0">Tuna Académica do Politécnico do Porto</p>
      </div>
      <div className="d-flex">
        <a href="https://www.facebook.com/TunaGatunos" target="_blank">
          <FacebookLogo
            size={30}
            style={{ marginRight: "0.75rem" }}
            color="white"
            weight="bold"
          />
        </a>
        <a href="https://www.instagram.com/tunagatunos/" target="_blank">
          <InstagramLogo
            size={30}
            style={{ marginRight: "0.75rem" }}
            color="white"
            weight="bold"
          />
        </a>
        <a href="https://www.youtube.com/@Gatunos" target="_blank">
          <YoutubeLogo
            size={30}
            style={{ marginRight: "0.75rem" }}
            color="white"
            weight="bold"
          />
        </a>
      </div>
    </div>
  );
};

export default Footer;
