import { useMemo, useState } from "react";
import BootstrapCarousel from "react-bootstrap/Carousel";
import classes from "./Carousel.module.scss";
import { Spinner } from "@phosphor-icons/react";

const Carousel = () => {
  const [downloading, setDownloading] = useState<boolean>(false);

  const images = useMemo(
    () => [
      "/media/carousel/1-2023-10-17.webp",
      "/media/carousel/2-2023-10-17.webp",
      "/media/carousel/3-2023-10-17.webp",
      "/media/carousel/4-2023-10-17.webp",
      "/media/carousel/5-2023-10-17.webp",
    ],
    []
  );

  return (
    <div className={classes.container}>
      <BootstrapCarousel
        controls={false}
        indicators={false}
        interval={5000}
        className={classes.carousel}
      >
        {images.map((image: string, index: number) => {
          return (
            <BootstrapCarousel.Item key={index}>
              <img
                src={image}
                alt="foto de palco"
                style={{ objectFit: "cover" }}
              />
            </BootstrapCarousel.Item>
          );
        })}
      </BootstrapCarousel>
      <div className={classes.logo}>
        <img src="/media/alcatraz_2024.svg" width="80%" />
        <a
          href="http://gatunos.pt/forms/index.php/1?lang=pt"
          target="_blank"
          className="btn btn-primary text-white fw-semibold mt-3 mt-lg-0"
        >
          Vota na tua tuna preferida!
        </a>
        <button
          disabled={downloading}
          onClick={() => {
            setDownloading(true);
            fetch("/media/booklet_2024.pdf")
              .then((buffer) => {
                buffer.blob().then((blob) => {
                  window.open(URL.createObjectURL(blob), "_blank");
                  setDownloading(false);
                });
              })
              .catch(() => {
                setDownloading(false);
              });
          }}
          className="btn btn-secondary d-flex align-items-center text-white text-uppercase fw-semibold mt-3"
        >
          {downloading ? (
            <Spinner size={20} weight="bold" className="me-1 spinner" />
          ) : null}{" "}
          Booklet
        </button>
      </div>
    </div>
  );
};

export default Carousel;
