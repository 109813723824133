import { Container } from "react-bootstrap";

import About from "./pages/About/About";
import Carousel from "./pages/Carousel/Carousel";
import Memories from "./pages/Memories/Memories";
import Posters from "./pages/Posters/Posters";
import Footer from "./components/Footer";
import Movie from "./pages/Movie/Movie";

function App() {
  return (
    <Container fluid className="p-0">
      <div className="background" />
      <Carousel />
      <About />
      <Movie />
      <Memories />
      <Posters />
      <Footer />
    </Container>
  );
}

export default App;
