import clsx from "clsx";
import classes from "./About.module.scss";
import { Container } from "react-bootstrap";

const About = () => {
  return (
    <div className={classes.container}>
      <Container
        className={clsx(classes.content, "rounded", "col-12 col-lg-8 col-xl-6")}
      >
        <h2 className="text-align-center mb-5">
          Diverte-te e sê bem vindo ao ALCATRAZ!
        </h2>
        <div className="fs-5 ">
          <p>
            Desde há 26 edições que locais como o extenso areal, o Aqueduto de
            Santa Clara ou o Casino da Póvoa servem como pano de fundo para o
            Festival de Tunas dos Gatunos.
          </p>
          <p>Em 2024, não será diferente, mas nada será igual!</p>
          <p>
            Em plenas comemorações dos 30 anos da nossa tuna, as cidades de
            Póvoa de Varzim e Vila do Conde voltam a receber, já nos próximos
            dias 24 e 25 de maio, mais uma edição do Festival de Tunas dos
            Gatunos, onde as quatro tunas a concurso - Afonsina (Universidade do
            Minho), Trovantina (Politécnico de Leiria) Tuna TS (Politécnico do
            Porto) e VenusMonti (Universidade de Lisboa) - disputarão os prémios
            deste certame.
          </p>
          <p>
            A Noite de Serenatas à Cidade da Póvoa de Varzim, que decorrerá nas
            imediações da Casa da Juventude, pelas 22h de sexta-feira, dia 24,
            servirá como abertura deste evento.
          </p>
          <p>
            Já no sábado, dia 25, o Teatro Municipal de Vila do Conde volta a
            abrir as suas portas à grande noite de concurso do Festival, num
            espetáculo com cerca de 3 horas de duração e que promete trazer boa
            música, espírito tuneril e muitas surpresas, e que contará com a
            apresentação do Carlos Contente, criador do podcast "Zé Carioca" e
            com participação extraconcurso da Afrodituna assim como dos próprios
            Gatunos, que encerram, com chave-de-ouro, o certame.
          </p>
          <p>
            Para intensificar o espírito académico, poderás contar com o WARM UP
            ALCATRAZ, sexta-feira, a partir das 21h, na Casa da Juventude da
            Póvoa de Varzim e com a já tradicional ALCATRAZ PARTY, na madrugada
            de sábado para domingo, a partir da 1h, no nosso Espaço 68-70, no
            Campus 2.
          </p>
          <p>Temos a certeza que não vais querer perder! </p>
          <p>
            Bilhetes à venda no Teatro Municipal de Vila do Conde, Auditório
            Municipal de Vila do Conde,{" "}
            <a
              className="text-white"
              href="http://gatunos.bol.pt"
              target="_blank"
            >
              bol.pt
            </a>{" "}
            e nos locais habituais.
          </p>
          <p>Gatunos: Sempre Juntos, Sempre Fortes!</p>
        </div>
      </Container>
    </div>
  );
};

export default About;
