import { Container } from "react-bootstrap";
import classes from "./Movie.module.scss";

const Movie = () => {
  return (
    <div className={classes.container}>
      <Container className={classes.content}>
        <h2 className="text-align-center mb-4">After Movie 2023</h2>
        <iframe
          className="col-12 col-lg-10 col-xl-9"
          height="400px"
          src="https://www.youtube-nocookie.com/embed/KgAhdFkgylI?si=3D3xC-mOi1wU-8qL&amp;controls=0"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        ></iframe>
      </Container>
    </div>
  );
};

export default Movie;
